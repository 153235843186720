import Vue from 'vue'
import VueRouter from 'vue-router'

const RecipientLogin=() => import('../views/recipient-login.vue')
const ChildrenTest=() => import('../views/children-test.vue')
const AdultTest=() => import('../views/adult-test.vue')
const DoctorChart=() => import('../views/doctor-chart.vue')
const DoctorList=() => import('../views/doctor-list.vue')
const DivaPreface=() => import('../views/diva-preface.vue')
const DoctorPayed=() => import('../views/doctor-payed.vue')
const PersonalityTest=() => import('../views/personality-test.vue')
const BehaviorTest=() => import('../views/behavior-test.vue')
const TestList=() => import('../views/test-list.vue')
const AdultChildren=() => import('../views/adult-children.vue')


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes=[
    {
        path: '/',
        name: 'recipient-login',
        meta:{index:1,title: '登录'},
        component:RecipientLogin  
    },
    {
        path: '/children-test',
        name: 'children-test',
        meta:{index:2,title: '儿童'},
        component:ChildrenTest  
    },
    {
        path: '/adult-test',
        name: 'adult-test',
        meta:{index:3,title: '成人'},
        component:AdultTest  
    },
    {
        path: '/doctor-chart',
        name: 'doctor-chart',
        meta:{index:4,title: '汇总'},
        component:DoctorChart  
    },
    {
        path: '/doctor-list',
        name: 'doctor-list',
        meta:{index:5,title: '列表'},
        component:DoctorList  
    },
    {
        path: '/diva-preface',
        name: 'diva-preface',
        meta:{index:6,title: '介绍'},
        component:DivaPreface  
    },
    {
        path: '/doctor-payed',
        name: 'doctor-payed',
        meta:{index:7,title: '管理'},
        component:DoctorPayed  
    },
    {
        path: '/personality-test',
        name: 'personality-test',
        meta:{index:8,title: '量表'},
        component:PersonalityTest  
    },   
    {
        path: '/behavior-test',
        name: 'behavior-test',
        meta:{index:9,title: '量表'},
        component:BehaviorTest  
    }, 
    {
        path: '/test-list',
        name: 'test-list',
        meta:{index:10,title: '量表'},
        component:TestList  
    }, 
    {
        path: '/adult-children',
        name: 'adult-children',
        meta:{index:11,title: '量表展示'},
        component:AdultChildren  
    }, 
    
]
const router = new VueRouter({
    //mode:'history',
    // base:process.env.BASE_URL,
    routes,	
})
export default router